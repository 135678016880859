import React from "react"
import { getNewestDate, getPromotionLink, generatePath } from "../lib/utils"
import Layout from "../components/Layout"
import CompareBody from "../components/CompareBody"
import PageTitle from "../components/PageTitle"
import CompareSidebarSearch from "../components/CompareSidebarSearch"
import Seo from "../components/Seo/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Breadcrumb from "../components/Breadcrumb"

export const query = graphql`
    query ($slug: [String!], $location: String, $locale: String) {
        datoCmsLocation(websiteUrl: {eq: $location}) {
            country {
                countryName
                countryCode
            }
            flagImage {
                gatsbyImageData(
                    imgixParams: {colors: 10, h: "20", w: "20", fit: "crop", auto: "format", mask: "ellipse"}
                    placeholder: BLURRED
                )
            }
            compareSidebarInfoNode {
                childMarkdownRemark {
                html
                }
            }
            singleMetaTitleTemplate
            singleMetaDescriptionTemplate
            currencySymbol
            dimensionsSymbol
            weightSymbol
            timezone
        }
        allDatoCmsMattress(
            filter: {
                locale: {eq: $locale}, 
                slug: {in: $slug}, 
                location: {
                    websiteUrl: {eq: $location}
                    }
                }
            ) {
            nodes {
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "330", w: "660", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                        placeholder: BLURRED
                    )
                }
                ...FragmentMattressFields
            }
        }
        allMattresses: allDatoCmsMattress(filter: {locale: {eq: $locale}, location: {websiteUrl: {eq: $location}}}) {
            nodes {
                slug
                name
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "35", w: "70", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                        placeholder: BLURRED
                    )
                }
            }
        }
        datoCmsTranslation(locale: {eq: $locale}) {
            ...FragmentTranslationFields
        }
    }`

const Mattress = ({ data, pageContext }) => {
    const { name, buttons, meta, brand, mattressImage, slug } = data.allDatoCmsMattress.nodes?.[0] || {}

    let pageTitle = data.datoCmsTranslation.mattress //default value
    pageTitle = data.datoCmsTranslation.mattress + ' ' + name

    let affiliateLink = null
    let allCompareMattressesSizes = []

    affiliateLink = getPromotionLink(data.allDatoCmsMattress.nodes?.[0])

    for (let i = 0; i < buttons.length; i++) {
        let isRepeatedSize = allCompareMattressesSizes.indexOf(buttons[i].size.sizeName)
        if (isRepeatedSize === -1) {
            //not found so lets add the size
            allCompareMattressesSizes.push(buttons[i].size.sizeName)
        }
    }

    let allDates = []
    allDates.push(meta.updatedAt)
    allDates.push(brand.meta.updatedAt)
    let newestDate = getNewestDate(allDates, data.datoCmsLocation.timezone, pageContext.locale)
    const mainImage = getImage(mattressImage)
    //SEO metadata
    let metaTitle = data.datoCmsLocation.singleMetaTitleTemplate
    let metaDescription = data.datoCmsLocation.singleMetaDescriptionTemplate
    let metaImage = mattressImage.gatsbyImageData.images.fallback.src
    let metaSlug = generatePath(
        [slug],
        pageContext.locale,
        pageContext.countryCode,
        data.datoCmsTranslation.slugMattress
    )

    metaTitle = metaTitle.replace('%%title%%', name)
    metaTitle = metaTitle.replace('%%currentyear%%', newestDate.year)

    metaDescription = metaDescription.replace('%%title%%', name)
    //metaDescription = metaDescription.replace('%%currentmonth%%', newestDate.month)

    const breadcrumbData = [
        {
            'link': `/${data.datoCmsLocation.country.countryCode}`,
            'image': data.datoCmsLocation.flagImage,
            'label': data.datoCmsLocation.country.countryName
        },
        {
            'link': null,
            'label': data.datoCmsTranslation.mattress
        }
    ]


    return (
        <Layout
            locale={pageContext.locale}
            queryTranslation={data.datoCmsTranslation}
            countryCode={pageContext.countryCode}
            footerText={data.datoCmsLocation.compareSidebarInfoNode}
            countryName={data.datoCmsLocation.country.countryName}
            flagImage={data.datoCmsLocation.flagImage}
        >
            <Seo
                title={metaTitle}
                description={metaDescription}
                image={metaImage}
                pathname={metaSlug}
                article
            />
            <div className="bg-zone-1">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between">
                    <main className="w-full lg:w-3/4 lg:pr-8">
                        <Breadcrumb
                            items={breadcrumbData}
                            homeLabel={data.datoCmsTranslation.home}
                        />

                        <PageTitle
                            newestDate={newestDate}
                            title={pageTitle}
                            link={affiliateLink}
                            category={data.datoCmsTranslation.mattress}
                            lastUpdatedOn={data.datoCmsTranslation.lastUpdatedOn}
                            rel="nofollow noopener sponsored"
                        />

                        <div className="mb-6 text-center px-2 md:px-4 xl:px-0">
                            {affiliateLink ? (
                                <>
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        href={affiliateLink}
                                        target="_blank"
                                        rel="nofollow noopener sponsored"
                                        id="AffTitleClick-img-single"
                                        title={data.datoCmsTranslation?.altExternalLink}
                                    >
                                        <GatsbyImage image={mainImage} alt={pageTitle} className="custom-blend" />
                                    </a>
                                </>
                            ) : (
                                <GatsbyImage
                                    image={mainImage}
                                    alt={pageTitle}
                                    className="custom-blend"
                                    title={data.datoCmsTranslation?.altExternalLink}
                                />
                            )}
                        </div>

                        <CompareBody
                            mattresses={data.allDatoCmsMattress.nodes}
                            location={data.datoCmsLocation}
                            sizes={allCompareMattressesSizes}
                        />
                    </main>
                    <CompareSidebarSearch
                        allMattresses={data.allMattresses.nodes}
                        countryCode={pageContext.countryCode}
                        rawSlugs={pageContext.slug}
                        className="w-full px-2 md:px-4 xl:px-0 lg:w-1/4"
                    />
                </div>
            </div>
        </Layout>
    )
}

export default Mattress

